<template>
    <div id="wrapper" class="white-background">
        <nav class="navbar">
            <div class="navbar-brand">
                <a class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbar-menu" @click="showMobileMenu = !showMobileMenu">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>

            <div class="navbar-menu" id="navbar-menu" v-bind:class="{'is-active': showMobileMenu }">
                <div class="navbar-start">
                    <div class="navbar-item">
                        <router-link :to="{ name: 'wetlabhome' }" class="button adjusted">
                            <img src="@/assets/curvebio_logo.png" alt="Curve Bio Logo" />
                        </router-link>
                        <router-link :to="{ name: 'Fulfillment' }" class="button adjusted">Kit Fulfillment</router-link>
                        <router-link :to="{ name: 'Accession' }" class="button adjusted">Accessioning</router-link>
                        <router-link :to="{ name: 'PreAmp' }" class="button adjusted">PreAmp</router-link>
                        <router-link :to="{ name: 'PostAmp' }" class="button adjusted">PostAmp</router-link>
                    </div>
                </div>

                <div class="navbar-end">
                    <div class="navbar-item">
                        <form method="get" action="/wetlab/search">
                            <div class="field has-addons">
                                <div class="control">
                                    <input type="text" class="input search-bar" placeholder="Search" name="query">
                                </div>
                                <div class="control">
                                    <button class="button secondary">
                                        <span class="icon">
                                            <i class="fas fa-search"></i>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </form>

                        <router-link 
                            :to="{ name: 'login' }"
                            class="button secondary" >
                            {{ isLoggedIn ? 'Logout' : 'Login' }}
                        </router-link>
                    </div>
                </div>
            </div>
        </nav>

        <div class="layout-container">
            <!-- Left column for dynamic links based on the current route -->
            <div v-if="currentMenu"  class="layout-container-left">
                <div v-for="(section, index) in currentMenu" :key="index" class="layout-container-left">
                    <p class="left-list-header-text">{{ section.header }}</p>
                    <router-link
                        v-for="item in section.items"
                        :key="item.route"
                        :to="item.route"
                        class="left-list-text"
                        :class="{ 'is-active': $route.path === item.route }"
                    >
                        {{ item.label }}
                    </router-link>
                </div>
            </div>

            <!-- Main content -->
            <section class="section main-section">
                <router-view/>
            </section>
        </div>

        <footer class="footer light-grey-background">
            <p class="has-text-centered">Keep calm and execute well</p>
        </footer>
    </div>
</template>

<script>
import logoImage from '@/assets/curvebio_logo.png';

export default {
    name: 'Wetlab',
    data() {
    return {
        showMobileMenu: false,
        searchQuery: '',
        logoImage,
        menuItems: {
        fulfillment: [
            {
            header: 'Fulfillment Reports',
            items: [
                { label: 'Kit List', route: { name: 'kitlist' } },
                { label: 'Shipment List', route: { name: 'shipmentlist' } },
                { label: 'Site List', route: { name: 'facilitylist' } },
            ],
            },
            {
            header: 'Fulfillment Actions',
            items: [
                { label: 'Initiate Kits', route: { name: 'initiatekits' } },
                { label: 'Assemble Kits', route: { name: 'assemblekits' } },
                { label: 'On-Board New Site', route: { name: 'newfacility' } },
                { label: 'Ship Kits', route: { name: 'mailkits' } },
            ],
            },
        ],
        accession: [
            {
            header: 'Accession Reports',
            items: [
                { label: 'CRC Patients', route: { name: 'CRC Patients' } },
            ],
            },
            {
            header: 'Accession Actions',
            items: [
                { label: 'Accession Rack from Collection Site', route: { name: 'Accession Rack' } },
                { label: 'Create Freezer Objects', route: { name: 'Freezer Obj Creation' } },
                { label: 'Freezer Center', route: { name: 'Freezer Center' } },
            ],
            },
        ],
        preamp: [
            {
            header: 'PreAmp Reports',
            items: [
                { label: 'Output Plates', route: { name: 'PreAmp Plates' } },
            ],
            },
            {
            header: 'PreAmp Actions',
            items: [],
            },
        ],
        postamp: [
            {
            header: 'PostAmp Actions',
            items: [],
            },
        ],
        object: [
            {
            header: 'Object Reports',
            items: [
                { label: 'Kit List', route: { name: 'kitlist' } },
                { label: 'Shipment List', route: { name: 'shipmentlist' } },
                { label: 'Site List', route: { name: 'facilitylist' } },
            ],
            },
            {header: 'Object Actions',
            items: [],},
        ],
        },
    };
    },
    computed: {
    isLoggedIn() {
        return !!localStorage.getItem('authToken');
    },
    isFulfillment() {
        return this.$route.path.startsWith('/wetlab/fulfillment');
    },
    isAccession() {
        return this.$route.path.startsWith('/wetlab/accession');
    },
    isPreamp() {
        return this.$route.path.startsWith('/wetlab/preamp');
    },
    isPostamp() {
        return this.$route.path.startsWith('/wetlab/postamp');
    },
    isObject() {
        return this.$route.path.startsWith('/wetlab/models');
    },
    currentMenu() {
        if (this.isFulfillment) return this.menuItems.fulfillment;
        if (this.isAccession) return this.menuItems.accession;
        if (this.isPreamp) return this.menuItems.preamp;
        if (this.isPostamp) return this.menuItems.postamp;
        if (this.isObject) return this.menuItems.object;
        return null;
        },
    },
    methods: {
        toggleMobileMenu() {
            this.showMobileMenu = !this.showMobileMenu;
        },
    },
};
</script>


