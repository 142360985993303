import GenericWebpageView from '@/views/Wetlab/GenericWebpageView.vue';
import DynamicModelView from '@/views/Wetlab/DynamicModelView.vue';


export default [
  {
    path: `preamp`,
    name: 'PreAmp',
    component: GenericWebpageView,
  },
  {
    path: `preamp/preampbatch:pk`,
    name: 'PreAmpBatch',
    component: DynamicModelView,
  },
  {
    path: `preamp/preampplates`,
    name: 'PreAmp Plates',
    component: GenericWebpageView,
  },
]