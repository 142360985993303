<template>
    <div class="login-container">
        <div v-if="!isLoggedIn" class="login-box">
            <h2>Welcome to Wetlab Login for Curve Biosciences </h2>
            <p>Please sign in using your Curve Biosciences Google account to continue.</p>
            <button class="google-login-button" @click="onGoogleLoginSuccess">
                Sign in with Google
            </button>
        </div>

        <div v-else class="login-box">
            <h2>You are currently logged in.</h2>
            <button class="logout-button" @click="onLogout">Logout</button>
        </div>
    </div>
</template>


<script>
import axios from 'axios';

export default {
    name: 'GoogleLogin',
    data() {
        return {
            authorizationUrl: '',
            user: '',
            isLoggedIn: localStorage.getItem('authToken') ? true : false,
        };
    },
    mounted() {
        document.title = 'Wetlab Login | Curve Biosciences'
    },
    created() {
        axios.get(`${process.env.VUE_APP_WETLAB_URL}/auth/config/`)
            .then(response => {
                this.authorizationUrl = response.data.authorizationUrl;
                this.user = response.data.user;
            })
            .catch(error => {
                console.error("There was an error fetching the Google auth configuration:", error);
            });
    },

    methods: {
        onGoogleLoginSuccess() {
            window.location = this.authorizationUrl;
        },
        onLogout() {
            axios.get(`${process.env.VUE_APP_WETLAB_URL}/auth/logout/`, {
                headers: {
                    'Authorization': `Token ${localStorage.getItem('authToken')}`
                }
            })
            .then(response => {
                localStorage.removeItem('authToken');
                this.isLoggedIn = false;
                window.location.reload(); // Reload the page or handle post-logout behavior
            })
            .catch(error => {
                localStorage.removeItem('authToken');
                this.isLoggedIn = false;
                console.error("There was an error logging out:", error);
            });
        }
    },
};
</script>

<style scoped>
/* Center the login container */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #f5f6fa;
    font-family: 'Arial', sans-serif;
}

/* Style for the login box */
.login-box {
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 400px;
    width: 100%;
}

/* Header styling */
.login-box h2 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 1rem;
}

/* Button styling */
button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0.75rem 1rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* Google Login Button */
.google-login-button {
    background-color: #4285f4;
    color: #fff;
    margin-top: 1rem;
}

.google-login-button:hover {
    background-color: #357ae8;
}


/* Logout Button */
.logout-button {
    background-color: #ff4c4c;
    color: #fff;
}

.logout-button:hover {
    background-color: #e84343;
}

/* Paragraph styling */
p {
    color: #666;
    font-size: 1rem;
}
</style>
