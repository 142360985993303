import NewFacility from '@/views/Wetlab/Fulfillment/NewFacility.vue'

import GenericWebpageView from '@/views/Wetlab/GenericWebpageView.vue';

export default [
  {
    path: 'fulfillment',
    name: 'Fulfillment',
    component: GenericWebpageView,
  },
  {
    path: 'fulfillment/facilitylist',
    name: 'facilitylist',
    component: GenericWebpageView,
  },
  {
    path: 'fulfillment/kitlist',
    name: 'kitlist',
    component: GenericWebpageView,
  },
  {
    path: 'fulfillment/shipmentlist',
    name: 'shipmentlist',
    component: GenericWebpageView,
  },
  {
    path: 'fulfillment/initiatekits',
    name: 'initiatekits',
    component: GenericWebpageView,
  },
  {
    path: 'fulfillment/assemblekits',
    name: 'assemblekits',
    component: GenericWebpageView,
  },
  {
    path: 'fulfillment/mailkits',
    name: 'mailkits',
    component: GenericWebpageView,
  },

  {
    path: `fulfillment/newfacility`,
    name: 'newfacility',
    component: NewFacility
  }
]
