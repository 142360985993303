<template>
  <div class="auth-callback">
    <div v-if="isLoading">
      <p>Logging in...</p>
    </div>
    <div v-else-if="error" class="error-message">
      <p>{{ error }}</p>
      <button @click="navigateToLogin">Return to Login</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'AuthCallback',
  data() {
    return {
      isLoading: true,
      error: null,
    };
  },
  mounted() {
    document.title = 'Auth Callback';

    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get('code');
    const state = queryParams.get('state');
    const error = queryParams.get('error');

    if (code) {
      this.handleGoogleLogin(code, state);
    } else if (error) {
      this.handleError(`Google login error: ${error}`);
    } else {
      this.handleError('No authorization code or error returned from Google.');
    }
  },
  methods: {
    async handleGoogleLogin(code, state) {
      try {
        const response = await axios.get(`${process.env.VUE_APP_WETLAB_URL}/auth/login/google/`, {
          params: { code, state },
        });

        console.log('Login successful');
        localStorage.setItem('authToken', response.data.token);

        this.isLoading = false;
        this.$router.push({ name: 'wetlabhome' }).then(() => {
          window.location.reload();
        });
      } catch (error) {
        console.error('Login failed:', error);
        this.handleError('Login failed. Please try again.');
      }
    },
    handleError(message) {
      this.error = message;
      this.isLoading = false;
      this.$router.push({ name: 'login' });
    },
    navigateToLogin() {
      this.$router.push({ name: 'login' });
    },
  },
};
</script>


<style scoped>
p {
  font-size: 16px;
}
</style>
