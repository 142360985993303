<template>
  <div>
    <div v-if="isLoading" class="loading-symbol"></div>

    <ResponseComponent
      :responseMessage="responseMessage"
      :responseError="responseError"
      :tableComponentExists="tableComponentExists"
      :tableData="tableData"
    />

    <component 
      v-if="showPageElements" 
      v-for="block in pageElements" 
      :key="block.index" 
      :is="block.component" 
      :block="block" 
      @formSubmitted="submitForm"
    ></component>
  </div>
</template>

<script>
import ActionMixin from '@/mixins/ActionMixin.js'

export default {
  mixins: [ActionMixin],
  mounted() {
    document.title = this.pageTitle;
  },
  created() {
    // Initial fetch when the component is created
    this.fetchPageData();
  },
  watch: {
    // Watch for route changes and refetch data
    $route(to, from) {
      if (to.path !== from.path) {
        this.fetchPageData();
        document.title = this.pageTitle; // Update the page title
      }
    }
  },
  computed: {
    showPageElements() {
      return !this.responseMessage; // Hide page elements if there’s a response message
    },
    pageTitle() {
      // Generate a title based on the route name
      return this.$route.name
        ? `${this.$route.name.charAt(0).toUpperCase() + this.$route.name.slice(1)}`
        : '';
    }
  },
  methods: {
    fetchPageData() {
      // Fetch the structure dynamically based on the route path
      const modelPath = this.$route.path;
      this.fetchPageStructure(`${modelPath}/`);
    },
    submitForm(formData) {
      const modelPath = this.$route.path;
      this.handleFormSubmission(`${modelPath}/`, formData);
    },
  },
};
</script>
