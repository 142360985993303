<template>
  <div>
    <div v-if="isLoading" class="loading-symbol"></div>

    <ResponseComponent
      :responseMessage="responseMessage"
      :responseError="responseError"
      :tableComponentExists="tableComponentExists"
      :tableData="tableData"
    />

    <!-- Only display this section if there's no responseMessage -->
    <div v-if="!responseMessage">
      <h1 style="font-size: 36px; font-weight: bold;">On-Board a New Site</h1>
      <div style="height: 12px;"></div>

      <div>
        <label for="organization">Organization</label>
        <input
          id="organization"
          type="text"
          v-model="organization"
          placeholder="Enter the organization name"
        />
      </div>

      <div>
        <label for="site_number">Site Number</label>
        <input
          id="site_number"
          type="text"
          v-model="site_number"
          placeholder="Enter the site number"
        />
      </div>

      <div>
        <label for="pi_last_name">PI Last Name</label>
        <input
          id="pi_last_name"
          type="text"
          v-model="pi_last_name"
          placeholder="Enter the PI Last Name"
        />
      </div>

      <div>
        <label for="pi_first_name">PI First Name</label>
        <input
          id="pi_first_name"
          type="text"
          v-model="pi_first_name"
          placeholder="Enter the PI First Name"
        />
      </div>

      <div>
        <label for="phone_number">Phone Number</label>
        <input
          id="phone_number"
          type="text"
          v-model="phone_number"
          placeholder="Enter the phone number"
        />
      </div>

      <div style="margin-top: 10px;">
        <label for="recipient_last_name">Recipient Last Name</label>
        <input
          id="recipient_last_name"
          type="text"
          v-model="recipient_last_name"
          placeholder="Enter the recipient's last name"
        />
      </div>

      <div style="margin-top: 10px;">
        <label for="recipient_first_name">Recipient First Name</label>
        <input
          id="recipient_first_name"
          type="text"
          v-model="recipient_first_name"
          placeholder="Enter the recipient's firstname"
        />
      </div>

      <div style="margin-top: 10px;">
        <label for="recipient_role">Recipient Role</label>
        <input
          id="recipient_role"
          type="text"
          v-model="recipient_role"
          placeholder="Enter the recipient's role"
        />
      </div>

      <div style="margin-top: 10px;">
        <label for="recipient_email">Recipient Email</label>
        <input
          id="recipient_email"
          type="text"
          v-model="recipient_email"
          placeholder="Enter the recipient's email"
        />
      </div>

      <div>
        <label for="street-line">Street Line</label>
        <input
          id="street-line"
          type="text"
          placeholder="Enter your street"
          v-model="streetLine"
          ref="streetLineInput"
        />
      </div>

      <div style="margin-top: 10px;">
        <label for="street_line_2">Optional Street Line 2</label>
        <input
          id="street_line_2"
          type="text"
          v-model="street_line_2"
          placeholder="Enter optional street line 2"
        />
      </div>

      <div style="margin-top: 20px;">
        <label>City: </label>
        <input v-model="city" />
      </div>

      <div style="margin-top: 10px;">
        <label>State: </label>
        <input v-model="state" />
      </div>

      <div style="margin-top: 10px;">
        <label>Country: </label>
        <input v-model="country" />
      </div>

      <div style="margin-top: 10px;">
        <label>Zipcode: </label>
        <input v-model="zipcode" />
      </div>

      <button @click="submitAddress">Submit Address</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import ActionMixin from '@/mixins/ActionMixin.js';

export default {
  mixins: [ActionMixin],
  data() {
    return {
      organization: '',
      site_number: '',
      pi_last_name: '',
      pi_first_name: '',
      phone_number: '',
      recipient_last_name: '',
      recipient_first_name: '',
      recipient_role: '',
      recipient_email: '',
      streetLine: '', // This will store the formatted street line
      street_line_2: '',
      city: '',
      state: '',
      country: '',
      zipcode: '',
      responseMessage: '',
      responseError: '',
      isLoading: false,
      tableData: {
        items: [],
        columns: []
      },
      tableComponentExists: false,
      googleApiLoaded: false // To track whether the API has been loaded
    };
  },
  mounted() {
    this.loadGoogleMapsApi();
    document.title = 'New Facility';
  },
  methods: {
    async generateFormSubmission(apiUrl, formData) {
      this.handleFormSubmission(`${process.env.VUE_APP_WETLAB_URL}/fulfillment/newfacility/`, formData);
    },
    initializeAutocomplete() {
      // Initialize Google Places autocomplete on the street line input
      const autocomplete = new google.maps.places.Autocomplete(this.$refs.streetLineInput, {
        types: ['address'], // Only show address predictions
      });

      // Listen for the place_changed event
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (place && place.address_components) {
          this.extractAddressComponents(place.address_components);
        }
      });
    },

    extractAddressComponents(addressComponents) {
      let streetNumber = '';
      let route = '';

      // Extract and map Google Places address components to form fields
      addressComponents.forEach(component => {
        const componentType = component.types[0];
        switch (componentType) {
          case 'street_number':
            streetNumber = component.long_name;
            break;
          case 'route':
            route = component.long_name;
            break;
          case 'locality':
            this.city = component.long_name;
            break;
          case 'administrative_area_level_1':
            this.state = component.short_name;
            break;
          case 'country':
            this.country = component.long_name;
            break;
          case 'postal_code':
            this.zipcode = component.long_name;
            break;
          default:
            break;
        }
      });

      // Set the street line as street number + route
      this.streetLine = `${streetNumber} ${route}`.trim();

      // Update the street line input field directly
      this.$refs.streetLineInput.value = this.streetLine;
    },

    loadGoogleMapsApi() {
      // Check if Google Maps API is already loaded
      if (this.googleApiLoaded) return;

      // Create a script element and set the src to Google Maps API URL
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBMWdrNaa4f9gDWR-rhlRVApJndy-4OUcQ&libraries=places`;
      script.async = true;
      script.defer = true;

      // Append the script to the body
      document.body.appendChild(script);

      // Set the googleApiLoaded flag to true when the script is successfully loaded
      script.onload = () => {
        this.googleApiLoaded = true;
        this.initializeAutocomplete(); // Initialize autocomplete after the script is loaded
      };
    },

    submitAddress() {
      const formData = {
        organization: this.organization,
        site_number: this.site_number,
        pi_last_name: this.pi_last_name,
        pi_first_name: this.pi_first_name,
        phone_number: this.phone_number,
        recipient_last_name: this.recipient_last_name,
        recipient_first_name: this.recipient_first_name,
        recipient_role: this.recipient_role,
        recipient_email: this.recipient_email,
        streetLine: this.streetLine,
        street_line_2: this.street_line_2,
        city: this.city,
        state: this.state,
        country: this.country,
        zipcode: this.zipcode,
        action: 'NewFacility',
      };

      this.generateFormSubmission(`${process.env.VUE_APP_WETLAB_URL}/fulfillment/newfacility/`, formData);
    }
  },
};
</script>

<style scoped>
input {
  display: block;
  margin-bottom: 10px;
  padding: 8px;
  width: 300px;
}
button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}
button:hover {
  background-color: #0056b3;
}
</style>
