<template>
  <div class="table-container">
    <table class="table">
      <thead>
        <tr>
          <th v-if="showCheckboxes" style="width: 50px;">
            <input type="checkbox" @change="toggleSelectAll" v-model="selectAll" />
          </th>
          <th
            v-for="(column, index) in block.columns"
            :key="index"
            style="font-size: 16px; background-color: #18B6E9;"
          >
            <div class="column-header">
              {{ column }}
              <input
                type="text"
                v-model="columnSearchQuery[column]"
                @input="filterTable"
                placeholder="Filter..."
                class="search-input"
              />
            </div>
          </th>
        </tr>
      </thead>

      <tbody id="table-body">
        <tr v-if="filteredItems.length === 0">
          <td :colspan="block.columns.length + (showCheckboxes ? 1 : 0)" style="text-align: center; padding: 10px;">
            No results found.
          </td>
        </tr>
        <tr v-for="(item, index) in paginatedItems" :key="index" class="table-row">
          <td v-if="showCheckboxes">
            <input type="checkbox" v-model="selectedItems" :value="item" />
          </td>
          <td v-for="(column, indexColumn) in block.columns" :key="indexColumn">
            <!-- Collapsible Column Logic -->
            <template v-if="isCollapsibleColumn(column)">
              <div class="collapsible-content">
                <span @click="toggleCollapse(index, column)" class="collapse-arrow">
                  {{ isCollapsed(index, column) ? `▶ Click to view ${column}` : '▼' }}
                </span>
                <span v-if="!isCollapsed(index, column)" class="preserve-newlines">
                  {{ formatItemContent(item[column]) }}
                </span>
              </div>
            </template>
            <template v-else>
              <span v-if="isLinkItem(item[column])">
                <a class="curve-link" :href="fullUrl(item[column].url)">
                  {{ item[column].name }}
                </a>
              </span>
              <template v-else-if="isArrayLinkItems(item[column])">
                <div v-for="(linkItem, linkIndex) in item[column]" :key="linkIndex">
                  <a class="curve-link" :href="fullUrl(linkItem.url)">
                    {{ linkItem.name }}
                  </a>
                  <span v-if="linkIndex < item[column].length - 1">, </span>
                </div>
              </template>
              <template v-else>
                <span class="preserve-newlines" v-html="item[column] !== null && item[column] !== undefined ? item[column] : ''"></span>
              </template>
            </template>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="pagination-controls" v-if="totalPages > 1">
      <button class="download-button" @click="changePage(currentPage - 1)" :disabled="currentPage === 1">Previous</button>
      <span id="page-info">{{ `Page ${currentPage} of ${totalPages}` }}</span>
      <button class="download-button" @click="changePage(currentPage + 1)" :disabled="currentPage === totalPages">Next</button>
    </div>

    <div class="download-controls">
      <button class="download-button" @click="downloadCSV">Download Table as CSV</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    block: {
      type: Object,
      required: true
    },
    showCheckboxes: {
      type: Boolean,
      default: false
    },
    rowsPerPage: { // New prop
      type: Number,
      default: 25
    }
  },
  data() {
    const initialSearchQuery = {};
    for (const column of this.block.columns) {
      initialSearchQuery[column] = '';
    }
    return {
      columnSearchQuery: initialSearchQuery,
      currentPage: 1,
      selectedItems: [],
      selectAll: false,
      collapsedItems: {} // Track collapsed/expanded state for each row-column pair
    };
  },
  computed: {
    filteredItems() {
      return this.block.items.filter(item => {
        return this.block.columns.every(column => {
          const query = (this.columnSearchQuery[column] || '').toLowerCase();
          const value = item[column];
        
          if (value === null || value === undefined) return query === '';
        
          if (Array.isArray(value)) {
            return value.some(subValue => {
              if (subValue && typeof subValue === 'object' && subValue.hasOwnProperty('name')) {
                return subValue.name.toLowerCase().includes(query);
              }
              return subValue?.toString().toLowerCase().includes(query);
            });
          }

          if (typeof value === 'object' && value && value.hasOwnProperty('name')) {
            return value.name.toLowerCase().includes(query);
          }
        
          return value?.toString().toLowerCase().includes(query);
        });
      });
    },
    totalPages() {
      return Math.ceil(this.filteredItems.length / this.rowsPerPage); // Use prop
    },
    paginatedItems() {
      const startRow = (this.currentPage - 1) * this.rowsPerPage; // Use prop
      const endRow = startRow + this.rowsPerPage;
      return this.filteredItems.slice(startRow, endRow);
    }
  },
  methods: {
    fullUrl(url) {
      return `${process.env.VUE_APP_FRONTEND_URL}/${url}`;
    },
    isLinkItem(value) {
      return value && typeof value === 'object' && value.hasOwnProperty('name') && value.hasOwnProperty('url');
    },
    isArrayLinkItems(value) {
      return Array.isArray(value) && value.every(item => this.isLinkItem(item));
    },
    isCollapsibleColumn(column) {
      return this.block.collapse && this.block.collapse.includes(column);
    },
    toggleCollapse(rowIndex, column) {
      const key = `${rowIndex}-${column}`;
      this.collapsedItems[key] = !this.collapsedItems[key];
    },
    isCollapsed(rowIndex, column) {
      const key = `${rowIndex}-${column}`;
      return this.collapsedItems[key] !== undefined ? this.collapsedItems[key] : true;
    },
    changePage(page) {
      if (page < 1 || page > this.totalPages) return;
      this.currentPage = page;
    },
    filterTable() {
      this.currentPage = 1;
    },
    toggleSelectAll() {
      if (this.selectAll) {
        this.selectedItems = [...this.paginatedItems];
      } else {
        this.selectedItems = [];
      }
    },
    /**
     * Escapes a CSV field by wrapping it in double quotes if necessary
     * and escaping any existing double quotes within the field.
     * @param {string} field - The CSV field to escape.
     * @returns {string} - The escaped CSV field.
     */
    escapeCSVField(field) {
      if (field.includes('"')) {
        // Escape double quotes by replacing " with ""
        field = field.replace(/"/g, '""');
      }
      if (field.includes(',') || field.includes('"') || field.includes('\n')) {
        // Wrap the field in double quotes if it contains a comma, double quote, or newline
        field = `"${field}"`;
      }
      return field;
    },
    downloadCSV() {
      // Escape headers to handle any special characters
      const headers = this.block.columns.map(column => this.escapeCSVField(column)).join(',');
      
      // Function to strip HTML tags and return visible text
      const stripHTML = (htmlString) => {
          if (!htmlString || typeof htmlString !== 'string') return htmlString;
          const parser = new DOMParser();
          const doc = parser.parseFromString(htmlString, 'text/html');
          return doc.body.textContent || ""; // Extract visible text content
      };

      // Map each item to a CSV row
      const rows = this.filteredItems.map(item => {
        return this.block.columns.map(column => {
          const value = item[column];
          
          if (value === null || value === undefined) return '';
          
          if (Array.isArray(value)) {
            let formattedArray;

            if (value.length === 1) {
                // Return the single item's name directly without brackets
                formattedArray = value[0]?.name || '';
            } else {
                // Format as [item1, item2, ...] for arrays with multiple items
                formattedArray = `[${value.map(subValue => subValue.name).join(', ')}]`;
            }

            return this.escapeCSVField(formattedArray);
          }
          
          if (typeof value === 'object' && value.hasOwnProperty('name')) {
            // If the value is an object with a 'name' property
            return this.escapeCSVField(value.name);
          }
          
          const cleanValue = stripHTML(value.toString());
          return this.escapeCSVField(cleanValue);
        }).join(',');
      });
      
      // Combine headers and rows into CSV content
      const csvContent = [headers, ...rows].join('\n');
      
      // Create a Blob from the CSV content
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'table-data.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    formatItemContent(value) {
      return Array.isArray(value)
        ? value.map(subValue => subValue.name).join(', ')
        : value;
    }
  }
};
</script>

<style scoped>
.preserve-newlines {
  white-space: pre-line;
}

.collapse-arrow {
  cursor: pointer;
  font-size: 14px;
  margin-right: 5px;
}

.table-container {
  overflow-x: auto;
  margin: 0 auto;
  padding: 5px;
}

.table {
  background-color: white;
  margin-bottom: 0px;
}

.table th,
.table td {
  border: 1px solid #ddd;
  color: #2E2E33;
}

.table tr:last-child td {
  border-bottom: 1px solid #ddd;
}

.column-header {
  display: flex;
  flex-direction: column;
}

.search-input {
  width: 100%;
  margin-top: 5px;
}

.table tr:nth-child(even) {
  background-color: #F9FAFB;
}

.table tr:hover {
  background-color: #f2f2f2;
}

.pagination-controls #page-info {
  margin: 0 5px;
  font-size: 10px;
}

.download-button {
  font-weight: normal;
  font-size: 10px;
  padding: 3px 5px;
  background-color: #f2f2f2;
  color: black;
  border: 1px solid #aaa;
  cursor: pointer;
}

.download-button:hover {
  background-color: #ccc;
}
</style>
