import DynamicModelView from '@/views/Wetlab/DynamicModelView.vue';

export default [
  {
    path: 'models',
    children: [
      {
        path: 'facility:pk',
        name: 'facility',
        component: DynamicModelView,
      },
      {
        path: 'freezer:pk',
        name: 'freezer',
        component: DynamicModelView,
      },
      {
        path: 'freezerbox:pk',
        name: 'freezerbox',
        component: DynamicModelView,
      },
      {
        path: 'freezerrack:pk',
        name: 'freezerrack',
        component: DynamicModelView,
      },
      {
        path: 'freezertray:pk',
        name: 'freezertray',
        component: DynamicModelView,
      },
      {
        path: 'cfdna:pk',
        name: 'cfdna',
        component: DynamicModelView,
      },
      {
        path: 'kit:pk',
        name: 'kit',
        component: DynamicModelView,
      },
      {
        path: 'patient:pk',
        name: 'patient',
        component: DynamicModelView,
      },
      {
        path: 'plasma:pk',
        name: 'plasma',
        component: DynamicModelView,
      },
      {
        path: 'plate:pk',
        name: 'plate',
        component: DynamicModelView,
      },
      {
        path: 'primer:pk',
        name: 'primer',
        component: DynamicModelView,
      },
      {
        path: 'rack:pk',
        name: 'rack',
        component: DynamicModelView,
      },
      {
        path: 'seqlib:pk',
        name: 'seqlib',
        component: DynamicModelView,
      },
      {
        path: 'shipment:pk',
        name: 'shipment',
        component: DynamicModelView,
      },
      {
        path: 'tube:pk',
        name: 'tube',
        component: DynamicModelView,
      },
      {
        path: 'wholeblood:pk',
        name: 'wholeblood',
        component: DynamicModelView,
      },
    ],
  },
];
